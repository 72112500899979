import { useNavigate } from "react-router-dom";
import classes from "./AboutUs.module.css";

const AboutUs = ({showBtn=true}) => {
  const navigate = useNavigate();
  return (
    <>
      <section className={`${classes.BannerImage} `}>
        <div className="container-md">
          <div className="row">
            <div className="col-md-9 offset-md-8 col-lg-7 offset-lg-7 col-xl-7 offset-xl-5 col-xxl-7 offset-xxl-5  ">
              <div className={classes["about-text"]}>
                <h6>About Us</h6>
                <h2>
                  The art of creative communication. Impacting the world with
                  innovative solutions—one step at a time.
                </h2>
                <p>
                  At Canvax Lab, innovation is our ethos. From stunning visuals
                  to cutting-edge websites, our diverse services offer
                  comprehensive digital solutions. Our expertise spans design,
                  web & app development, and digital marketing, ensuring your
                  business stays ahead online.
                </p>
                <p>
                  We prioritize user-friendly interfaces and robust back-ends in
                  web development and seamless functionality with elegant design
                  in app development. Digital marketing for us means meaningful
                  engagement, fostering lasting connections.
                </p>
                <p>
                  We provide ongoing support for your digital infrastructure,
                  ensuring optimal performance. Your digital journey starts
                  here. Let's create unforgettable experiences together
                </p>
              </div>
              {showBtn && <div className={classes["field-button"]}>
                <button type="button" onClick={()=>(navigate('/Aboutus'))} className={classes.SubmitButton}>
                  Learn More
                </button>
              </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
