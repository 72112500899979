import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { NavLink,  Link } from "react-router-dom";

import logo from "../../../assets/CANVAXLAB.png";
import classes from "./Navbar.module.css"

const  OffCanvasNavbar=(props)=> {
  const expand = "lg";
  const navLinkData = [
    { id:"1",title: "Home", linkValue: "/", dropdownStatus: false },
    { id:"2", title: "Pricing", linkValue: "/Packages", dropdownStatus: false },

    // {
    //   id:"3",
    //   title: "Services",
    //   linkValue: "/services",
    //   dropdownStatus: true,
    //   dropdownItems: [
    //     { id:"3d1", title: "Custom Logo Design", linkValue: "/", dividerStatus: true },
    //     { id:"3d2", title: "Web Development", linkValue: "/", dividerStatus: true },
    //     {  id:"3d3",title: "NFTs", linkValue: "/", dividerStatus: false },
    //   ],
    // },
    { id:"4",title: "Services", linkValue: "/Services", dropdownStatus: false },
    { id:"4",title: "Portfolio", linkValue: "/Portfolio", dropdownStatus: false },
    {  id:"5",title: "About", linkValue: "/Aboutus", dropdownStatus: false },
    {  id:"6",title: "Contact", linkValue: "/Contactus", dropdownStatus: false },
  ];

  return (
    <>
      <>{props.children}</>
      <Navbar
        key={expand}
        bg=""
        expand={expand}
        variant="dark"
        className={`${"mb-3 pt-4 align-top"} ${classes.navbar}`}

      >
        <Container fluid="lg">
          <Navbar.Brand as={Link} to="/" className="">
            <img
              src={logo}
              width="150"
              className="d-inline-block align-top "
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            variant="dark"
            
          >
            <Offcanvas.Header closeButton closeVariant="white">
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img
                  src={logo}
                  width="120"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className={`${"justify-content-end flex-grow-1 pe-5"} `  }  >
                {navLinkData.map((item) =>
                  !item.dropdownStatus ? (
                    <Nav.Link as={NavLink} to={item.linkValue} key={item.id} className={classes['nav-link']} >
                      {item.title}
                    </Nav.Link>
                  ) : (
                    <NavDropdown
                      title={item.title}
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                      className={classes['nav-link']}
                      menuVariant="dark"
                      key={item.id}
                    >
                      {item.dropdownItems.map((dropdownItem) =>
                        !dropdownItem.dividerStatus ? (
                          <NavDropdown.Item
                            as={Link}
                            to={dropdownItem.linkValue}
                            className={classes['nav-link']}
                            key={dropdownItem.id}
                          >
                            {dropdownItem.title}
                          </NavDropdown.Item>
                        ) : (
                          <>
                            <NavDropdown.Item
                              as={Link}
                              to={dropdownItem.linkValue}
                              className={classes['nav-link']}
                              key={dropdownItem.id}
                            >
                              {dropdownItem.title}
                            </NavDropdown.Item>
                            <NavDropdown.Divider key={dropdownItem.id}/>{" "}
                          </>
                        )
                      )}
                    </NavDropdown>
                  )
                )}
              </Nav>
              <Form className="d-flex">
                <Button onClick={()=>{window.open('tel:447487234443')}} type="submit" className={classes.phoneButton}>+44-748-723-4443 </Button>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
     
      </Navbar>

    </>
  );
}

export default OffCanvasNavbar;
