import React,{Suspense,useRef} from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import About from './pages/About';
import Layout from "./Layout";
import LoadingSpinner from './components/UI/LoadingSpinner';
import Whatsapp from "./components/Layout/Whatsapp";
import Services from "./pages/Services";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Home = React.lazy( () => import("./pages/Home") );
const Packages = React.lazy( () => import("./pages/Packages") );
const Portfolio = React.lazy( () => import("./pages/Portfolio") );
const Contact = React.lazy( () => import("./pages/Contact") );

function App() {
  const tawkMessengerRef = useRef();
  // const handleMinimize = () => {
  //       tawkMessengerRef.current.minimize();
  //   };
  return (
    <React.Fragment>
      <Layout>
        <TawkMessengerReact
                propertyId="664cea3c9a809f19fb33751b"
                widgetId="1hue75vrm"
                ref={tawkMessengerRef}/>
      <Whatsapp/>
      <Suspense
          fallback={
            <div className="centered">
              <LoadingSpinner />
            </div>
          }
        >
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Aboutus" element={<About />} />
          <Route path="/Contactus" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/createrLink" element={<CreaterLink />} />

        </Routes>

        </Suspense>
      </Layout>
    </React.Fragment>
  );
}

function CreaterLink() {
  window.location.href = 'https://canvaxlab.com/';
  return null;
}

export default App;
